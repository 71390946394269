import React, { useContext, useState } from 'react';
import { Grid, Link as MaterialLink, Divider } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { WhiteLogoIcon } from 'components/icons/icons';

import { FeatureToggle } from 'react-feature-toggles/lib';
import { FEATURE_TOGGLE_DEBUG } from 'utils/dictionary/featureToggles';
import { PrimaryButton } from 'components/buttons/button';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import { AuthContext } from 'contexts/authContext';
import UserMenu from 'components/userMenu/userMenu';
import { ProfileContext } from 'contexts/profileContext';
import style from './headerV2.module.scss';

import { REACT_APP_FRONTDOOR_ISSUER } from 'utils/dictionary/env';
import FeaturesModal from 'components/featuresModal/featuresModal';
import { TenantContext } from 'contexts/tenantContext';

const HeaderV2 = () => {
  const location = useLocation();

  const { organizationAlias } = useContext(AuthContext);
  const { profile } = useContext(ProfileContext);
  const { tenant } = useContext(TenantContext);

  const [openFeatures, setOpenFeatures] = useState(false);
  const myHealthwiseUrl = `${REACT_APP_FRONTDOOR_ISSUER}/${organizationAlias}/applications${location.search}`;

  let userInfo = {
    firstName: `${profile.firstName}`,
    lastName: `${profile.lastName}`,
    email: `${profile.email}`,
    name: `${profile.name}`,
    logService: `${profile.logService}`,
    contentFeedback: `${profile.contentFeedback}`,
  };

  const skipToMainContent = e => {
    e.preventDefault();
    document.getElementById('main').focus();
  };

  return (
    <header>
      <nav aria-label="skip to main content">
        <a className={style.skipLink} href="#main" onClick={skipToMainContent}>
          Skip to main content
        </a>
      </nav>
      <nav aria-label="primary" className={style.appBar} position="sticky">
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          className={style.gridContainer}
        >
          <Grid item container xs="auto" alignItems="center">
            <MaterialLink
              aria-label="Open My Healthwise landing page"
              className={style.logoWrapper}
              href={myHealthwiseUrl}
            >
              <WhiteLogoIcon className={style.logo} />
            </MaterialLink>
            <Divider orientation="vertical" className={style.divider} />

            <div className={style.appName}>User Management</div>
          </Grid>
          <Grid
            item
            container
            xs="auto"
            alignItems="center"
            className={style.toggle}
          >
            <FeatureToggle featureName={FEATURE_TOGGLE_DEBUG}>
              <PrimaryButton
                buttonText="Feature Toggles"
                handleClick={() => setOpenFeatures(true)}
              />
              <FeaturesModal open={openFeatures} setOpen={setOpenFeatures} />
            </FeatureToggle>
          </Grid>

          <Grid item container xs="auto" alignItems="center">
            <BusinessIcon className={style.icon} />
            <span className={style.orgName}>{tenant.subscriptionName}</span>
            <Divider orientation="vertical" className={style.divider} />
            <PersonIcon className={style.icon} />
            <span className={style.name}>{userInfo.firstName}</span>
            <UserMenu user={userInfo} />
          </Grid>
        </Grid>
      </nav>
    </header>
  );
};

export default HeaderV2;
